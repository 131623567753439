
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CountDown from "./components/mixin/countDown";
interface PrivateReg {
  loginId: string;
  code: string;
  // nickname: string;
  password: string;
  verifyPassword: string;
  invitation?: string | number;
}

@Component({
  name: "PrivateRegister",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class PrivateRegister extends mixins(CountDown) {
  // 注册
  public formData: PrivateReg = {
    loginId: "",
    code: "",
    // nickname: "",
    password: "",
    verifyPassword: "",
    invitation: "",
  };
  // 验证规则
  public rules: any = {
    loginId: [
      { required: true, message: "请输入邮箱", trigger: "blur" },
      {
        pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        message: "请输入正确邮箱",
      },
    ],
    code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
    // nickname: [{ required: true, message: "请输入用户名", trigger: "blur" }],
    password: [
      { required: true, message: "请设置6-12位登录密码", trigger: "blur" },
      {
        pattern: /^[^\u4e00-\u9fa5]{6,12}$/,
        message: "密码请设置6-12位的非中文字符",
      },
    ],
    verifyPassword: [
      { required: true, message: "密码6-12位，区分大小写", trigger: "blur" },
      { validator: this.verifyPasswordCheck },
    ],
  };

  // 获取验证码
  queryCode(): void {
    this.ruleForm.validateField("loginId", (errorMessage: string) => {
      if (errorMessage === "") {
        let params = {
          loginId: this.formData.loginId,
          modelNumber: "002",
        };
        this.isQueryCode = true;
        this.againQueryTime();
        this.$apis.getEmailCode(params).then((res: any) => {
          if (res.code === "200") {
            this.$message.success("操作成功");
          }
        });
      } else {
        this.$message.warning("请输入正确邮箱");
      }
    });
  }
  verifyPasswordCheck(rule: any, value: string, callback: any): void {
    let password: string = this.formData.password;
    if (value === "") {
      callback(new Error("请输入密码"));
    }
    if (value && password && value.trim() !== password.trim()) {
      callback(new Error("两次密码不一致"));
    }
    callback();
  }
  // 注册
  handleRegister(): void {
    this.ruleForm.validate((valid: any) => {
      if (valid) {
        this.$apis.register(this.formData).then((res: any) => {
          if (res.code === "200") {
            this.$message.success("注册成功");
            this.$router.push({ path: "/user/login" });
          } else {
            this.$message.warning(res.msg);
          }
        });
      }
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("ruleForm")
  ruleForm!: any;
  //创建前钩子函数
  created(): void {}
}
